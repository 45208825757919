(function() {
    'use strict';

    angular.module('beacon.app')
        .component('dynamicContentType', {
            templateUrl: '/assets/views/content/elements/types/dynamic-content/dynamic-content.tpl.html',
            controller: DynamicContentType,
            bindings: {
                langArray: '<?',
                contentForm: '=?',
                contentData: '=?',
                contentGroupsArray: '<?',
            }
        });

    function DynamicContentType(
        $q,
        $timeout,
        $scope,
        $stateParams,
        StatesFactory,
        UtilitiesService,
        ContentHelper,
        PermissionsService,
        DYNAMIC_CONTENT_IMAGE_POSITION,
        DYNAMIC_CONTENT_IMAGE_SCALING,
        DYNAMIC_CONTENT_IMAGE_REPEAT,
        TenantHelper,
        ContentDataService,
        ImageUploadFactory,
    ) {
        const vm = this;

        const { isLanguageFilled, translateContent } = ContentHelper;
        const URL_CREATOR = window.URL || window.webkitURL;
        const NO_TEMPLATE_ID = 0;

        /**
         * @type {ImageUploader}
         */
        let imageUploaderDay;

        /**
         * @type {ImageUploader}
         */
        let imageUploaderNight;

        vm.maxLength = 60; // title maximum length
        vm.isContentGroupSelectBoxVisible = PermissionsService.isContentGroupMenuItemVisible;
        vm.state = StatesFactory.ContentStates;
        vm.templates = TenantHelper.getDynamicContentTemplates();
        vm.imagePositions = DYNAMIC_CONTENT_IMAGE_POSITION;
        vm.imageScalings = DYNAMIC_CONTENT_IMAGE_SCALING;
        vm.imageRepeats = DYNAMIC_CONTENT_IMAGE_REPEAT;
        vm.showPreview = true;

        /**
         * @type {{
         *   title: string,
         *   message: string,
         *   background: string,
         *   titleNight: string,
         *   messageNight: string
         *   backgroundNight: string
         * }}
         */
        vm.textColor = {};
        vm.colorPickerApi = {
            title: {
                onChange: (_api, color) => {
                    vm.textColor = {
                        ...vm.textColor,
                        title: color
                    };
                    vm.data.titleColor = color;
                },
            },
            message: {
                onChange: (_api, color) => {
                    vm.textColor = {
                        ...vm.textColor,
                        message: color
                    };
                    vm.data.messageColor = color;
                },
            },
            background: {
                onChange: (_api, color) => {
                    vm.textColor = {
                        ...vm.textColor,
                        background: color
                    };
                    vm.data.backgroundColor = color;
                },
            },
            titleNight: {
                onChange: (_api, color) => {
                    vm.textColor = {
                        ...vm.textColor,
                        titleNight: color
                    };
                    vm.data.titleColorNight = color;
                },
            },
            messageNight: {
                onChange: (_api, color) => {
                    vm.textColor = {
                        ...vm.textColor,
                        messageNight: color
                    };
                    vm.data.messageColorNight = color;
                },
            },
            backgroundNight: {
                onChange: (_api, color) => {
                    vm.textColor = {
                        ...vm.textColor,
                        backgroundNight: color
                    };
                    vm.data.backgroundColorNight = color;
                },
            },
        };

        vm.videoResizeTypes = [
            {
                value: 'default',
                label: 'VIDEO_FIT_ORIGINAL',
            },
            {
                value: 'cover',
                label: 'VIDEO_FIT_SIMPLE',
            },
            {
                value: 'contain',
                label: 'VIDEO_FIT_FILL',
            }
        ];
        vm.bgMediaRadioGroup = [
            {
                type: 'default',
                label: 'DEFAULT',
            },
            {
                type: 'image',
                label: 'IMAGE',
            },
            {
                type: 'video',
                label: 'VIDEO',
            }
        ];
        vm.bgMediaTypeSelected = vm.bgMediaRadioGroup[0].type;
        vm.selectedVideoFile;
        vm.videoPathPreview;
        vm.imageLoaderConfig = {
            minWidth: 200,
            minHeight: 200,
            center: true,
            fullInfo: true,
        };

        vm.onImageSelectDay = onImageSelectDay;
        vm.onImageSelectNight = onImageSelectNight;
        vm.isLanguageFilled = isLanguageFilled;
        vm.translateHandler = translateHandler;
        vm.getDefaultTitle = getDefaultTitle;
        vm.onLanguageChange = onLanguageChange;
        vm.onContentNameChange = onContentNameChange;
        vm.onContentTitleChange = onContentTitleChange;
        vm.onTranslationPreviewClick = onTranslationPreviewClick;
        vm.onVideoSelect = onVideoSelect;
        vm.onVideoResizeTypeChange = onVideoResizeTypeChange;
        vm.onBgMediaTypeChange = onBgMediaTypeChange;

        vm.$onInit = () => {
            vm.isError = UtilitiesService.inputValidator(vm.contentForm);
            vm.content = vm.contentData;
            _setDefaults();
            vm.data = vm.content.data;
            vm.canAutoEditTitle = vm.state.type === "new" && !Object.keys(vm.contentData.title).length;
            _setWatchers();
            vm.langId = vm.contentData.language_id;
            _setDefaultColorScheme();
            _setTextColorDefaults();
            _setVideoDefaults();
            _setDefaultBgMediaType();
            _setDefaultImageDisplayMode();
            _setImageUploaders();
        }

        /**
         * Language change handler
         * @param {string} oldValue
         * @returns {undefined}
         */
        function onLanguageChange(oldValue) {
            vm.contentData.title = vm.contentData.title || {};
            vm.contentData.message = vm.contentData.message || {};
            vm.contentData.background = vm.contentData.background || {};

            if (!isLanguageFilled(vm.contentData, oldValue)) {
                delete vm.contentData.title[oldValue];
                delete vm.contentData.message[oldValue];
                delete vm.contentData.background[oldValue];
            }

            vm.langId = vm.contentData.language_id;

            vm.canAutoEditTitle = false;
        }

        function onContentNameChange() {
            if (vm.canAutoEditTitle) {
                vm.contentData.title[vm.langId] = vm.data.name;
            }
        }

        function onContentTitleChange() {
            vm.canAutoEditTitle = false;
        }

        /**
         * Image file input handler
         * @param {object} imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         * @returns {*}
         */
        function onImageSelectDay(imageFile, uploadToS3) {
            const image = _getImageFromLoaderResponse({ imageFile, uploadToS3 });

            _setImageDay(image);
            _setDisplayDefaults();
        }

        /**
         * Image file input handler
         * @param {object} imageFile
         * @param {boolean} uploadToS3 Need upload to S3
         * @returns {*}
         */
        function onImageSelectNight(imageFile, uploadToS3) {
            const image = _getImageFromLoaderResponse({ imageFile, uploadToS3 });

            _setImageNight(image);
            _setDisplayDefaults();
        }

        /**
         * Called when user selects video file from the local machine
         * @param {File} file video file
         */
        function onVideoSelect(file) {
            const fileReader = new FileReader();

            fileReader.onload = function(_event) {
                vm.data.video = {
                    resizeType: vm.videoResizeTypes[0].value,
                    src: URL_CREATOR.createObjectURL(file),
                };
                vm.videoResizeTypeSelected = vm.data.video.resizeType;

                vm.selectedVideoFile = file;
                // timeout fixes change detection so value updates in the view after the file select
                $timeout(() => {
                    vm.videoPathPreview = file.name;
                });
            };

            fileReader.readAsDataURL(file);
        }

        /**
         * Update form data on video resize
         */
        function onVideoResizeTypeChange() {
            vm.data.video = {
                ...vm.data.video,
                resizeType: vm.videoResizeTypeSelected,
            };
        }

        /**
         * Called on "background media type" radio group value change.
         * Updates form data based on selected media type
         */
        function onBgMediaTypeChange() {
            vm.data.bgMediaType = vm.bgMediaTypeSelected;

            switch (vm.bgMediaTypeSelected) {
                case 'image':
                    vm.data.video = null;
                    vm.selectedVideoFile = null;
                    vm.videoPathPreview = null;
                break;
                case 'video':
                    _resetImageRelatedSettings();
                break;
                default:
                    vm.data.video = null;
                    vm.selectedVideoFile = null;
                    vm.videoPathPreview = null;
                    _resetImageRelatedSettings();
            }
        }

        /**
         * Called when user selects video file from the local machine
         * @param {File} file video file
         */
        function onVideoSelect(file) {
            const fileReader = new FileReader();

            fileReader.onload = function(_event) {
                vm.data.video = {
                    resizeType: vm.videoResizeTypes[0].value,
                    src: URL_CREATOR.createObjectURL(file),
                };
                vm.videoResizeTypeSelected = vm.data.video.resizeType;

                vm.selectedVideoFile = file;
                // timeout fixes change detection so value updates in the view after the file select
                $timeout(() => {
                    vm.videoPathPreview = file.name;
                });
            };

            fileReader.readAsDataURL(file);
        }

        /**
         * Update form data on video resize
         */
        function onVideoResizeTypeChange() {
            vm.data.video = {
                ...vm.data.video,
                resizeType: vm.videoResizeTypeSelected,
            };
        }

        /**
         * Upload video file to remote host.
         * Triggers global loading animation while in progress
         */
        function uploadVideo() {
            return new Promise(resolve => {
                const formData = new FormData();
                formData.append('video', vm.selectedVideoFile);

                ContentDataService.uploadVideo(formData).then(function (src) {
                    vm.data.video = {
                        src,
                        resizeType: vm.videoResizeTypeSelected,
                    };

                    resolve();
                });
            })
        }

        /**
         * Called on "background media type" radio group value change.
         * Updates form data based on selected media type
         */
        function onBgMediaTypeChange() {
            vm.data.bgMediaType = vm.bgMediaTypeSelected;

            switch (vm.bgMediaTypeSelected) {
                case 'image':
                    vm.data.video = null;
                    vm.selectedVideoFile = null;
                    vm.videoPathPreview = null;
                    vm.data.template = NO_TEMPLATE_ID;
                break;
                case 'video':
                    onImageDelete();
                break;
                default:
                    vm.data.video = null;
                    vm.selectedVideoFile = null;
                    vm.videoPathPreview = null;
                    onImageDelete();
            }
        }

        /**
         * Clear image related data
         */
        function onImageDelete() {
            vm.content.image_src = null;
            vm.data.imageNightSrc = null;
            vm.data.template = NO_TEMPLATE_ID;
            vm.data.imagePosition = null;
            vm.data.imageScaling = null;
            vm.data.imageRepeat = null;
            vm.data.imageOnly = false;
        }

        /**
         * Translate handler
         *
         * @param tgtLang - target language
         */
        function translateHandler(tgtLang) {
            translateContent(vm.contentData, vm.langArray, tgtLang);
            vm.langId = tgtLang.id;
        }

        function onTranslationPreviewClick(langId) {
            const oldLang = vm.contentData.language_id;
            vm.contentData.language_id = langId;
            onLanguageChange(oldLang);
        }

        /**
         * Returns default translation
         *
         * @param {object} item
         * @returns {string} Title
         */
        function getDefaultTitle(item) {
            let keys = Object.keys(item),
                title = '';

            if (keys.length) {
                title = item[keys[0]];
            }

            return title;
        }


        // PRIVATE METHODS

        /**
         * @private
         * Set default values to the model
         * @returns {void}
         */
        function _setDefaults() {
            if ($stateParams.type === 'new' && !$stateParams.copy) {
                vm.content.data = {
                    multiLanguage: true,
                    slidingLanguage: false,
                    fullPageSliding: false,
                    showTitle: true,
                    showMessage: true,
                    template: NO_TEMPLATE_ID,
                    slidingTimeout: 2,
                    name: '',
                };
            }

            if (vm.content.data.showMessage === undefined) {
                vm.content.data.showMessage = true;
            }
        }

        /**
         * @private
         * Clears all image related settings and sets default template
         * @returns {void}
         */
        function _resetImageRelatedSettings() {
            vm.data.template = NO_TEMPLATE_ID;
            vm.data.imageOnly = false;
            vm.data.imagePosition = null;
            vm.data.imageScaling = null;
            vm.data.imageRepeat = null;
        }

        /**
         * @private
         * Upload video file to remote host.
         * Triggers global loading animation while in progress
         * @returns {Promise<void>}
         */
        function _uploadVideo() {
            return new Promise(resolve => {
                if (!vm.selectedVideoFile) {
                    resolve();
                    return;
                }

                const formData = new FormData();
                formData.append('video', vm.selectedVideoFile);

                ContentDataService.uploadVideo(formData).then(function (src) {
                    vm.data.video = {
                        src,
                        resizeType: vm.videoResizeTypeSelected,
                    };

                    resolve();
                });
            })
        }

        /**
         * @private
         * Count total slide transition time
         * @returns {void | null}
         */
        function _getTotalTransitionTime() {
            if (!vm.data.slidingLanguage) {
                return null;
            }
            const filledMessagesCount = Object.keys(vm.content.message)
                .filter(langId => vm.content.message[langId])
                .length;
            const slidesCount = vm.data.fullPageSliding
                ? filledMessagesCount
                : filledMessagesCount - 1;
            return slidesCount * vm.data.slidingTimeout;
        }

        /**
         * @private
         * Triggers preview change detection so it re-renders
         * @returns {void}
         */
        function _refreshPreview() {
            vm.showPreview = false;
            $timeout(() => {
                vm.showPreview = true;
            });
        }

        /**
         * @private
         * On finish handler
         * @returns {void}
         */
        function _onFinish(event, option) {
            vm.data.totalTransition = _getTotalTransitionTime();

            $q.all([
                imageUploaderDay.run(),
                imageUploaderNight.run(),
                _uploadVideo()
            ]).then(() => {
                option.callback({
                    type: vm.state.type,
                    contentData: angular.copy(vm.content),
                });
            });
        }

        /**
         * @private
         * Sets default values for "Day" and "Night" image uploaders
         * @returns {void}
         */
        function _setImageUploaders() {
            imageUploaderDay = new ImageUploadFactory.ImageUploader({
                initialImageUrl: vm.content.image_src,
                onImageUploaded: imageUrl => {
                    vm.content.image_src = imageUrl;
                }
            });

            imageUploaderNight = new ImageUploadFactory.ImageUploader({
                initialImageUrl: vm.data.imageNightSrc,
                onImageUploaded: imageUrl => {
                    vm.data.imageNightSrc = imageUrl;
                }
            });
        }

        /**
         * @private
         * Get image relative to provided params from image loader
         * @param {{ imageFile: object, uploadToS3: boolean }} response uploader response
         * @returns {Blob | string}
         */
        function _getImageFromLoaderResponse({ imageFile, uploadToS3 }) {
            return imageFile
                ? (uploadToS3 ? imageFile.blob : imageFile.blob.name)
                : '';
        }

        /**
         * @private
         * Set "day" image for preview
         * @param {Blob | string} image
         */
        function _setImageDay(image) {
            imageUploaderDay.setImage(image);
            vm.content.image_src = image
                ? imageUploaderDay.getImagePreview()
                : image;
        }

        /**
         * @private
         * Set "night" image for preview
         * @param {Blob | string} image
         */
        function _setImageNight(image) {
            imageUploaderNight.setImage(image);
            vm.data.imageNightSrc = image
                ? imageUploaderNight.getImagePreview()
                : image;
        }

        /**
         * @private
         * Reset image position and scaling when uploading an image
         * @returns {void}
         */
        function _setDisplayDefaults() {
            vm.data.template = null;
            vm.data.imagePosition = vm.imagePositions[0].id;
            vm.data.imageScaling = vm.imageScalings[2].id;
            vm.data.imageRepeat = vm.imageRepeats[0].id;
        }

        /**
         * @private
         * Set default image display mode ('day' or 'night')
         * @returns {void}
         */
        function _setDefaultImageDisplayMode() {
            vm.data.isImageNightMode = vm.data.isImageNightMode || false;
        }

        /**
         * @private
         * Set default color scheme
         * @returns {void}
         */
        function _setDefaultColorScheme() {
            if (!vm.data.colorScheme) {
                vm.data.colorScheme = 'default';
            }
        }

        /**
         * @private
         * Set default color values for title and message
         * @returns {void}
         */
        function _setTextColorDefaults() {
            vm.data.titleColor = vm.data.titleColor || '#272524';
            vm.data.messageColor = vm.data.messageColor || '#272524';
            vm.data.backgroundColor = vm.data.backgroundColor || '#ffffff';
            vm.data.titleColorNight = vm.data.titleColorNight || '#dddddd';
            vm.data.messageColorNight = vm.data.messageColorNight || '#dddddd';
            vm.data.backgroundColorNight = vm.data.backgroundColorNight || '#272524';

            vm.textColor = {
                title: vm.data.titleColor,
                message: vm.data.messageColor,
                background: vm.data.backgroundColor,
                titleNight: vm.data.titleColorNight,
                messageNight: vm.data.messageColorNight,
                backgroundNight: vm.data.backgroundColorNight,
            };
        }

        /**
         * @private
         * Set default video scaling/size
         * @returns {void}
         */
        function _setVideoDefaults() {
            if (vm.data.video) {
                vm.videoPathPreview = vm.data.video.src;
            }
            vm.videoResizeTypeSelected = vm.data.video ? vm.data.video.resizeType : vm.videoResizeTypes[0].value;
        }

        /**
         * @private
         * Set background media type defaults
         * @returns {void}
         */
        function _setDefaultBgMediaType() {
            function getDefaultValue() {
                if (vm.content.image_src || vm.data.imageNightSrc) {
                    return vm.bgMediaRadioGroup[1].type;
                }

                if (vm.data.video) {
                    return vm.bgMediaRadioGroup[2].type;
                }

                return vm.bgMediaRadioGroup[0].type;
            }

            vm.bgMediaTypeSelected = vm.data.bgMediaType ? vm.data.bgMediaType : getDefaultValue();
        }

        /**
         * @private
         * @returns {void}
         */
        function _setWatchers() {
            $scope.$watch(
                () => vm.contentForm && vm.contentForm.$valid,
                isFormValid => {
                    vm.state.canFinish = isFormValid
                });

            $scope.$watch(
                () => vm.data,
                () => _refreshPreview(),
                true,
            );

            $scope.$on('content-finish', _onFinish);
        }

    }
})();
