(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TenantHelper', TenantHelperController);

    function TenantHelperController(
        StorageFactory,
        DYNAMIC_CONTENT_TEMPLATES,
    ) {

        return {
            getDynamicContentTemplates,
            isTimetableFilterVisible,
            getCurrentTenant,
        };

        function getDynamicContentTemplates() {
            const tenant = getCurrentTenant();
            const NO_TEMPLATE_ID = 0;

            return DYNAMIC_CONTENT_TEMPLATES.filter(
                template => [NO_TEMPLATE_ID, ...tenant.dynamicContentTemplates.includes(template.id)]
            );
        }

        function getCurrentTenant() {
            const STORAGE = StorageFactory.Storage('Main');
            return STORAGE.currentUserInfo.subdomain.tenant;
        }

        function isTimetableFilterVisible(filterId) {
            const tenant = getCurrentTenant();
            return tenant.allowedTimetableOptions.includes(filterId)
        }
    }
}());
