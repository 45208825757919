(function() {
    'use strict';

    angular.module('beacon.app')
        .component('modifyTagsDialog', {
            templateUrl: '/assets/views/common/components/content-tags/modify-tags-dialog/modify-tags-dialog.tpl.html',
            controller: ModifyTagsDialog,
            bindings: {
                tags: '<',
                onSave: '&',
                onCancel: '&',
            }
        });

    /**
     * @param $scope
     *
     * @property {string[]} tags
     * @property {function({tags: string[]})} onChanged
     * @constructor
     */
    function ModifyTagsDialog(
        $scope,
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            $scope.value = angular.copy($ctrl.tags);
        }

        $scope.onCancel = () => {
            $ctrl.onCancel && $ctrl.onCancel();
        }

        $scope.onSave = () => {
            $ctrl.onSave && $ctrl.onSave({
                tags: $scope.value,
            });
        }
    }
})();
