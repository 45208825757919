(function() {
    'use strict';

    angular.module('beacon.app')
        .component('audienceCarParksPicker', {
            controller: AudienceCarParksPickerController,
            templateUrl: '/assets/views/common/directives/audience-picker/audience-car-parks-picker/audience-car-parks-picker.tpl.html',
            bindings: {
                value: '=',
            },
        });

    /**
     * @property {{
     *    carParkId: number,
     *    subAreas: string[]
     * }[]} value
     * @constructor
     */
    function AudienceCarParksPickerController(
        $scope,
        ShareParkDataService,
    ) {
        const $ctrl = this;

        $scope.carParks = [];
        $scope.loaded = false;

        $ctrl.$onInit = () => {
            _loadCarParks().then(() => $scope.loaded = true);
        }

        $scope.onCarParkClick = (carPark) => {
            const index = $ctrl.value.findIndex(val => val.carParkId === carPark.external_id);

            if (index >= 0) {
                $ctrl.value.splice(index, 1);
            } else {
                $ctrl.value.push({
                    carParkId: carPark.external_id,
                    subAreas: [],
                })
            }
        }

        $scope.onSubAreaClick = (carPark, subArea) => {
            const val = $ctrl.value.find(val => val.carParkId === carPark.external_id);

            if (!val) {
                $ctrl.value.push({
                    carParkId: carPark.external_id,
                    subAreas: [subArea.type],
                });
            } else if (val.subAreas.includes(subArea.type)) {
                const index = val.subAreas.findIndex(type => type === subArea.type);

                val.subAreas.splice(index, 1);
            } else {
                val.subAreas.push(subArea.type);
            }
        }

        $scope.isCarParkActive = (carPark) => {
            return $ctrl.value.some(val => val.carParkId === carPark.external_id);
        }

        $scope.isSubAreaActive = (carPark, subArea) => {
            return $ctrl.value.some(
                val => val.carParkId === carPark.external_id && val.subAreas.includes(subArea.type)
            );
        }

        function _loadCarParks() {
            return ShareParkDataService.getCarParks()
                .then(response =>
                    $scope.carParks = response.plain().sort((a, b) => a.name.localeCompare(b.name))
                )
        }
    }
})();
