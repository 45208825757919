(function() {
    'use strict';

    angular.module('beacon.app')
        .factory('campaignPushState', function() {
            return {
                views: {
                    CAMPAIGN_DETAILS: 0,
                    AUDIENCE: 1,
                    SCREENS: 2,
                }
            };
        })
        .controller('PushCampaignController', PushCampaignController);

    function PushCampaignController(
        $state,
        $stateParams,
        $interval,
        $scope,
        $moment,
        CampaignDataService,
        PermissionsService,
        PopupService
    ) {
        const vm = this;

        const { actions, generateCampaignPushPermission } = PermissionsService;

        // public methods
        vm.addPushCampaign = addPushCampaign;
        vm.copyPushCampaign = copyPushCampaign;
        vm.deletePushCampaign = deletePushCampaign;
        vm.updatePushCampaign = updatePushCampaign;
        vm.updatePushCampaigns = updatePushCampaigns;
        vm.filtersChange = filtersChange;
        vm.searchQuery = '';
        init();

        // public properties
        vm.createCampaignPushPermission = generateCampaignPushPermission(actions.create);
        vm.totalItems = $stateParams.paginationData ? $stateParams.paginationData.count : 0;
        vm.pushCampaigns = [];
        vm.visibleCampaigns = [];
        vm.showPlannedNotifications = true;
        vm.showPassedNotifications = true;

        // for filtering notifications
        vm.currentDate = moment().format('YYYY-MM-DD HH:mm:ss');

        /**
         * @type {ListItemDetailsConfig}
         */
        const detailsConfig = {
            languagePicker: true,
            columns: [
                {
                    title: 'DETAILS',
                    fields: [
                        {
                            label: 'NAME',
                            value: item => item.name,
                        },
                        {
                            label: 'DESCRIPTION',
                            value: item => item.description,
                        },
                        {
                            label: 'PUSH_AT',
                            value: item => item.push_date
                                ? $moment(item.push_date).format('LL HH:mm')
                                : '-',
                        },
                    ],
                },
                {
                    title: 'CONTENT',
                    fields: [
                        {
                            label: 'TITLE',
                            value: item => item.title,
                            languageEncoded: true,

                        },
                        {
                            label: 'TEXT',
                            value: item => item.text,
                            languageEncoded: true,
                        },
                    ],
                },
                {
                    title: 'IMAGES',
                    fields: [
                        {
                            label: 'ICON',
                            value: item => item.icon_src,
                            languageEncoded: true,
                            image: true,
                            maxWidth: '50px',
                        },
                        {
                            label: 'IMAGE',
                            value: item => item.image_src,
                            languageEncoded: true,
                            image: true,
                            maxWidth: '300px',
                        },
                    ],
                },
            ],
        };

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'campaignTitle',
                    width: '40',
                    title: 'name'
                },
                {
                    name: 'STATE',
                    class: 'campaignState',
                    translate: true,
                    width: '20',
                    title: 'state'
                },
                {
                    name: 'PUSH_DATE',
                    class: 'campaignPushDate',
                    width: '20',
                    title: 'push_date'
                }
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: vm.deletePushCampaign,
                        permissionAction: actions.delete,
                    },
                    {
                        class: 'copyBtn',
                        callback: vm.copyPushCampaign,
                        permissionAction: actions.create,
                    },
                    {
                        class: 'editBtn',
                        callback: vm.updatePushCampaign,
                        permissionAction: actions.modify,
                        isVisible: isEditButtonVisible
                    },
                    {
                        class: item => item.pinned ? 'pinActiveBtn' : 'pinBtn',
                        callback: onPin,
                    },
                ]
            },
            updateCallback: vm.updatePushCampaigns,
            generatePermissionName: generatePermissionName,
            additionalData: {
                detailsConfig,
            }
        };

        // private methods

        function onPin($event, campaign) {
            if ($event) {
                $event.preventDefault();
                $event.stopPropagation();
            }

            CampaignDataService.pin(campaign)
                .then(() => {
                    campaign.pinned = !campaign.pinned;
                });
        }

        /**
         * Create new Push Campaign button handler
         */
        function addPushCampaign() {
            $state.go('app.addPushCampaign', {
                paginationData: {
                    page: 1,
                    itemsPerPage: Number(angular.element('.paginationControls select').val().replace('number:', '')),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Handles filters change
         */
        function filtersChange() {
            const plannedState = 'PLANNED';
            const passedState = 'SENT';
            vm.pushCampaigns.forEach(campaign => {
                campaign.state = campaign.push_date > moment().format('YYYY-MM-DD HH:mm:ss') ? plannedState : passedState
            });
            if (vm.showPassedNotifications && vm.showPlannedNotifications) {
                vm.visibleCampaigns = vm.pushCampaigns;
            } else {
                vm.visibleCampaigns = vm.pushCampaigns.filter(campaign => {
                    if (vm.showPlannedNotifications) {
                        return campaign.state === plannedState;
                    }
                    if (vm.showPassedNotifications) {
                        return campaign.state === passedState;
                    }
                })
            }
        }

        /**
         * Copy push campaign
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function copyPushCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            const copiedCampaign = angular.copy(item);
            delete copiedCampaign.id;

            PopupService.showConfirmationPopup({
                text: 'DO_YOU_WANT_TO_COPY_THIS_CAMPAIGN',
                okButtonText: 'YES',
                cancelButtonText: 'NO'
            }, function() {
                const itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');
                $state.go('app.addPushCampaign', {
                    data: copiedCampaign,
                    paginationData: {
                        page: 1,
                        itemsPerPage: Number(itemsPerPage),
                        count: vm.totalItems,
                    },
                    copy: true,
                });
            });
        }

        /**
         * Removes push campaign
         *
         * @param {MouseEvent} $event
         * @param {object} campaignItem
         */
        function deletePushCampaign($event, campaignItem) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            PopupService.showConfirmationPopup({
                text: 'YOU_ARE_ABOUT_TO_DELETE_YOUR_CAMPAIGN',
                okButtonText: 'DELETE',
                cancelButtonText: 'CANCEL'
            }, function() {
                CampaignDataService.deleteCampaign(campaignItem.id)
                    .then(() => {
                        vm.totalItems -= 1;
                    })
                    .catch(console.error.bind(console));
            });
        }

        /**
         * Edit push campaign
         *
         * @param {MouseEvent} $event
         * @param {object} item
         */
        function updatePushCampaign($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();

            const page = angular.element('.pagination-page.active a')[0].innerHTML;
            const itemsPerPage = angular.element('.paginationControls select').val().replace('number:', '');

            $state.go('app.editPushCampaign', {
                data: item,
                paginationData: {
                    page: Number(page),
                    itemsPerPage: Number(itemsPerPage),
                    count: vm.totalItems
                }
            });
        }

        /**
         * Generates permission name for each button on content accordion-list
         * @param {object} button Button from accordion-list
         * @return {string} Permission name
         */
        function generatePermissionName(button) {
            if (!button.permissionAction) {
                return '';
            }

            return generateCampaignPushPermission(button.permissionAction);
        }

        /**
         * updates push campaigns list
         * @param {Number} page
         * @param {Number} itemsPerPage
         */
        function updatePushCampaigns(page, itemsPerPage) {
            const data = {
                page,
                itemsPerPage,
                type: 2,
                searchQuery: vm.searchQuery
            };

            CampaignDataService.getCampaigns(data)
                .then((response) => {
                    vm.totalItems = response.count;
                    vm.pushCampaigns = response.map(item => {
                        const additionalData = JSON.parse(item.additional_data);
                        const contents = item.contents.map(content => {
                            const title = JSON.parse(content.title);
                            const message = JSON.parse(content.message);

                            content.title = title[content.language_id];
                            content.message = message[content.language_id];
                            return content;
                        });
                        const pushDateUTC = new Date(item.started_at);
                        const localOffset = new Date().getTimezoneOffset() * 60000;
                        const localTime = pushDateUTC - localOffset;

                        return {
                            id: item.id,
                            name: item.name,
                            audiences: item.audiences,
                            audiencesData: item.audiencesData,
                            campaign_type: item.campaign_type,
                            contents,
                            content: contents[0],
                            clickOptions: additionalData.clickOptions,
                            description: item.description,
                            externalId: item.externalId,
                            title: additionalData.campaignContent.title || {},
                            text: additionalData.campaignContent.text || {},
                            icon_src: additionalData.campaignContent.icon_src || {},
                            image_src: additionalData.campaignContent.image_src || {},
                            pinned: item.pinned,
                            push_date: moment(localTime).format('YYYY-MM-DD HH:mm:ss'),
                            plain: item.plain,
                            state: item.started_at > vm.currentDate ? 'Planned' : 'Sent',
                            apps: additionalData.apps && additionalData.apps.split(',') || [],
                            locations: item.locations,
                            config: additionalData.campaignContent.config,
                        };
                    });

                    // apply current filters for loaded campaigns & change status
                    filtersChange();
                    $interval(filtersChange, 60000);

                })
                .catch(console.error.bind(console));
        }

        /**
         * Checks if event is planned
         * (only planned events can be modified)
         */
        function isEditButtonVisible(button, item) {
            const pushDateUTC = new Date(item.started_at);
            const localOffset = new Date().getTimezoneOffset() * 60000;
            const localTime = pushDateUTC - localOffset;
            return moment(localTime).format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss');
        }

        /**
         * Initialization method
         */
        function init() {
            vm.paginationData = $stateParams.paginationData;
        }
    }
}());
