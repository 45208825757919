(function() {
    'use strict';

    angular.module('beacon.app')
        .service('CampaignDataService', CampaignDataService);

    function CampaignDataService(
            Restangular,
            StorageFactory,
            UtilitiesService,
            $http,
        ) {
        return {
            getCampaigns,
            getRelatedScreenCampaigns,
            deleteCampaign,
            getCampaignsAudience,
            getAudience,
            campaign,
            create,
            update,
            getLocations,
            getRecipientsCount,
            syncContent,
            getCampaignsList,
            pin,
        };


        // Public methods

        /**
         * Return campaigns list
         */
        function getCampaigns(data) {
            return Restangular.all('campaigns').getList(data);
        }

        function getCampaignsList(data) {
            return Restangular.all('campaigns/list').getList(data);
        }

        function getRelatedScreenCampaigns(content) {
            return $http.get('/api/campaigns/screen/' + content.id).then(response => response.data);
        }

        /**
         * Delete campaign by it`s id
         *
         * @param {object} id
         */
        function deleteCampaign(id) {
            return Restangular.one('campaigns', id).remove();
        }

        /**
         * Return campaigns audience list
         */
        function getCampaignsAudience(campaignType, forceReload) {
            return new Promise((resolve, reject) => {
                let STORAGE = StorageFactory.Storage(campaignType);
                let audienceData = STORAGE.get('audienceData');
                if (forceReload || !audienceData) {
                    Restangular.one('campaigns/audience').getList().then((data) => {
                        let audienceData = UtilitiesService.plainResponse(data);
                        audienceData.forEach((item) => {
                            if (typeof item.filterValues === "undefined") {
                                item.filterValues = [];
                            }

                            if (!Array.isArray(item.filterValues)) {
                                item.filterValues = [item.filterValues];
                            }

                            item.id = Number(item.id);
                            item.categoryType = Number(item.categoryType);
                            item.filterValues.forEach((value) => {
                                value.id = Number(value.id);
                            });
                        });
                        resolve(STORAGE.set('audienceData', audienceData));
                    });
                } else {
                    resolve(audienceData);
                }
            });
            return Restangular.all('campaigns/audience').getList();
        }

        /**
         * Return campaigns audience list v2.0
         * Implemented without binding to Mobile Campaign Storage
         */
        function getAudience(forceReload) {
            return new Promise((resolve, reject) => {
                const STORAGE = new StorageFactory.Storage('Audience', true);
                const audienceData = STORAGE.get('data');
                if (forceReload || !audienceData) {
                    Restangular.one('campaigns/audience').getList().then((data) => {
                        const audienceData = UtilitiesService.plainResponse(data);
                        audienceData.forEach((item) => {
                            if (typeof item.filterValues === "undefined") {
                                item.filterValues = [];
                            }

                            if (!Array.isArray(item.filterValues)) {
                                item.filterValues = [item.filterValues];
                            }

                            item.id = Number(item.id);
                            item.categoryType = Number(item.categoryType);
                            item.filterValues.forEach((value) => {
                                value.id = Number(value.id);
                            });
                        });
                        resolve(STORAGE.set('data', audienceData));
                    });
                } else {
                    resolve(audienceData);
                }
            });
            return Restangular.all('campaigns/audience').getList();
        }

        /**
         * Return number of recipients after filter
         */
        function getRecipientsCount(data) {
            return Restangular.one('campaigns/recipients-count').get(data);
        }

        /**
         * Return campaign by it`s id
         *
         * @param {object} id
         */
        function campaign(id) {
            return Restangular.one('campaigns', id).get();
        }

        /**
         * Create new campaign
         *
         * @param {object} data
         */
        function create(data) {
            return Restangular.all('campaigns')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Update campaign
         *
         * @param {object} campaign
         * @param {object} data
         */
        function update(campaign, data) {
            data.append('_method', 'PUT');
            return campaign.customPOST(data, '', {}, {'Content-Type': undefined, '_method': 'PUT'});
        }

        /**
         * Sync campaign with contents
         *
         * @param id
         * @param data
         */
        function syncContent(id, data) {
            data.append('_method', 'PUT');
            return Restangular.one('campaigns/sync-content/', id).customPOST(data, '', {}, {'Content-Type': undefined});
        }

        /**
         * Get Locations
         * @param data
         * @returns {*|{method, params, headers}|Array}
         */
        function getLocations(data) {
            return Restangular.all('campaign/locations').getList(data);
        }

        function pin(content) {
            return $http.post(`/api/campaigns/pin/${content.id}`);
        }
    }
})();
