(function() {
    'use strict';

    angular.module('beacon.app').service('OpenIdOAuthService', OpenIdOAuthService);

    function OpenIdOAuthService($http) {
        return {
            getSignInLink,
            login,
            getScExternalRedirectUrl,
        };

        /**
         * Get openid authorization URL
         * @returns {Promise<{url: string}>}
         */
        function getSignInLink() {
            return $http.get('/api/oauth/openid/link').then(({ data }) => data);
        }

        /**
         * Login user after successful openid authorization
         * @param {string} code
         * @returns {Promise<{token: string}>}
         */
        function login(code) {
            return $http.post('/api/oauth/openid/login', { code }).then(({ data }) => data);
        }

        // #tempShareParkAppSSO
        /**
         * Send openid oauth "code" to external SmartCampus application
         * @param {string} code
         * @param {"sc-external" | "sc-external-demo"} appMode
         * @returns {Promise<{redirectUrl: string}>} redirect url to SmartCampus public invitations resource
         */
        function getScExternalRedirectUrl(code, appMode) {
            return $http.get(`/api/oauth/openid/${appMode}/${code}`).then(({ data }) => data);
        }
    }
})();
