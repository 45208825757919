(function() {
    'use strict';

    angular.module('dynamicContent')
        .constant('DYNAMIC_CONTENT_TEMPLATES', [
            {
                id: 0,
                name: 'NO_TEMPLATE',
                class: '',
            },
            {
                id: 1,
                name: 'WARNING',
                class: '--warning',
            },
            {
                id: 2,
                name: 'ALERT',
                class: '--alert',
            },
            {
                id: 4,
                name: 'LIGHT',
                class: '--light',
            },
            {
                id: 5,
                name: 'DARK',
                class: '--dark',
            },
            {
                id: 6,
                name: 'BVG_WARNING',
                class: '--warning --bvg-font',
            },
            {
                id: 7,
                name: 'BVG_ALERT',
                class: '--alert --bvg-font',
            },
            {
                id: 8,
                name: 'BVG_LIGHT',
                class: '--light  --bvg-font',
            },
            {
                id: 9,
                name: 'BVG_DARK',
                class: '--dark --bvg-font',
            },
            {
                id: 10,
                name: 'BVG_BRAND',
                class: '--bvg-brand',
            },
            {
                id: 11,
                name: 'BVG_CONSTRUCTION',
                class: '--bvg-layout --bvg-construction',
            },
            {
                id: 12,
                name: 'BVG_GENERAL_INFORMATION',
                class: '--bvg-layout --bvg-general',
            },
            {
                id: 13,
                name: 'BVG_NOTIFICATION',
                class: '--bvg-layout --bvg-notification',
            },
            {
                id: 14,
                name: 'BVG_CAUTION',
                class: '--bvg-layout --bvg-caution',
            },
            {
                id: 15,
                name: 'BVG_SBAHN_MESSAGE',
                class: '--bvg-layout --bvg-sbahn',
            },
            {
                id: 16,
                name: 'BVG_UBAHN_MESSAGE',
                class: '--bvg-layout --bvg-ubahn',
            },
            {
                id: 17,
                name: 'BVG_TRAM_MESSAGE',
                class: '--bvg-layout --bvg-tram',
            },
            {
                id: 18,
                name: 'BVG_BUS_MESSAGE',
                class: '--bvg-layout --bvg-bus',
            },
        ])
        .constant('DYNAMIC_CONTENT_IMAGE_POSITION', [
            {
                id: 1,
                name: 'CENTER_CENTER',
                style: 'center center'
            },
            {
                id: 2,
                name: 'LEFT_TOP',
                style: 'left top'
            },
            {
                id: 3,
                name: 'LEFT_BOTTOM',
                style: 'left bottom'
            },
            {
                id: 4,
                name: 'RIGHT_TOP',
                style: 'right top'
            },
            {
                id: 5,
                name: 'RIGHT_BOTTOM',
                style: 'right bottom'
            },
        ])
        .constant('DYNAMIC_CONTENT_IMAGE_SCALING', [
            {
                id: 1,
                name: 'AUTO',
                style: 'auto',
            },
            {
                id: 2,
                name: 'CONTAIN',
                style: 'contain'
            },
            {
                id: 3,
                name: 'COVER',
                style: 'cover',
            },
        ])
        .constant('DYNAMIC_CONTENT_IMAGE_REPEAT', [
            {
                id: 1,
                name: 'NO_REPEAT',
                style: 'no-repeat',
            },
            {
                id: 2,
                name: 'REPEAT',
                style: 'repeat'
            },
            {
                id: 3,
                name: 'REPEAT_X',
                style: 'repeat-x',
            },
            {
                id: 4,
                name: 'REPEAT_Y',
                style: 'repeat-y',
            },
        ])
    ;
}());
