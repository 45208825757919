(function() {
    'use strict';

    angular.module('trmServices')
        .service('TranslateService', TranslateService);

    function TranslateService(
        $translate,
    ) {
        /**
         * Sometimes we have {"en": "text", "de": "text"} and sometimes {"1": "text", "2": "text"}
         *
         * @type {{de: number, en: number}}
         */
        const lngMapping = {
            en: 1,
            de: 2,
        }

        return {
            getCurrentUiLanguageTranslation,
        }

        function getCurrentUiLanguageTranslation(value) { 
            try {
                const decoded = typeof value === 'string' 
                    ? JSON.parse(value) 
                    : value;

                if (typeof decoded !== 'object') {
                    return value;
                }

                const defaultLng = Object.keys(decoded)[0];
                const currentUiLang = $translate.use();

                if (!defaultLng) {
                    return ''
                }

                const currentLngValue = decoded[currentUiLang] || decoded[lngMapping[currentUiLang]];
                const defaultLngValue = decoded[defaultLng] || decoded[lngMapping[defaultLng]]

                return replaceNewLine(currentLngValue || defaultLngValue);
            } catch (error) {
                return value;
            }
                
            function replaceNewLine(value) {
                return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
        }
    }
})();
