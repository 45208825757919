(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('accordionList', accordionList);

    function accordionList() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/accordion-list/accordion-list.tpl.html',
            replace: true,
            controller: AccordionListController,
            controllerAs: 'accordionList',
            bindToController: true,
            scope: {
                data: '=accordionList',
                items: '=',
                counter: '=',
                updateCounter: '=',
                paginationData: '=',
                selectedItems: '=',
                selectedItem: '<',
                template: '@',
                noPagination: '=',
                longList: '=',
                disabled: '<',
                orderBy: '=',
            }
        };
    }

    /**
     * @typedef {Object} AccordionData
     *
     * @property {boolean} [needRadiobutton] - Indicates if radio buttons should be displayed.
     * @property {boolean} [needCheckbox] - Indicates if checkboxes should be displayed.
     * @property {boolean} [hideHeader] - If true, hides the header of the accordion list.
     * @property {function} updateCallback - A callback function to handle updates.
     * @property {function} [itemClickCallback] - A callback function triggered when an item is clicked.
     * @property {function} [checkboxClickCallback] - A callback function triggered when a checkbox is clicked.
     * @property {function} [onTagClick] - A function to handle tag clicks.
     * @property {Object[]} [columns]
     * @property {Object[]} [buttons]
     * @property {any[]} [additionalData]
     */

    /**
     * @property {AccordionData} data - The main data object containing configuration and callbacks for the accordion.
     * @property {Array<Object>} items - The list of items displayed in the accordion.
     * @property {number} counter - The total number of items.
     * @property {number} updateCounter - A value used to trigger updates to the list.
     * @property {Object} paginationData - Object containing pagination details.
     * @property {number} [paginationData.itemsPerPage] - Number of items per page.
     * @property {number} [paginationData.page] - Current page number.
     * @property {Array<Object>} selectedItems - Array of currently selected items (for multi-selection).
     * @property {Object} selectedItem - The currently selected item (for single-selection).
     * @property {string} template - The name or path of the custom template to use.
     * @property {boolean} [noPagination=false] - If true, disables pagination.
     * @property {boolean} [longList=false] - If true, indicates the list contains a large number of items.
     * @property {boolean} [disabled=false] - If true, disables interactions with the accordion list.
     * @property {string} [orderBy] - Field name or function used to order the items.
     */
    function AccordionListController(
        $scope,
        $translate,
        CAMPAIGN_TYPES
    ) {
        const vm = this;

        // public properties
        vm.itemsPerPageOptions = [10, 25, 50, 100];
        vm.itemsPerPage = vm.paginationData && vm.paginationData.itemsPerPage ? vm.paginationData.itemsPerPage : vm.itemsPerPageOptions[1];
        vm.currentPage = vm.paginationData && vm.paginationData.page ? vm.paginationData.page : 1;
        vm.needRadiobutton = vm.data && vm.data.needRadiobutton;
        vm.needCheckbox = vm.data && vm.data.needCheckbox;
        vm.hideHeader = vm.data && vm.data.hideHeader;

        // public methods
        vm.changeItemsCountHandler = changeItemsCountHandler;
        vm.itemClickHandler = itemClickHandler;
        vm.clickRadiobuttonHandler = clickRadiobuttonHandler;
        vm.checkboxClickHandler = checkboxClickHandler;
        vm.isSelected = isSelected;
        vm.isCheckboxSelected = isCheckboxSelected;

        vm.getValue = getValue;
        vm.defineClassForButton = defineClassForButton;
        vm.isTranslate = isTranslate;

        $scope.isButtonVisible = function(button, item) {
            if (typeof button.isVisible === 'function') {
                return button.isVisible(button, angular.isFunction(item.plain) ? item.plain() : item);
            }

            /**
             * TODO: 23.02.2018 serhii.kokovskyi This is legacy code. We need a common logic for defining a button visibility
             */
            let campaignType = Number(item.campaign_type);
            let isNewContent = Number(item.new_content);

            return (!button.screensOnly) || (button.screensOnly && campaignType === CAMPAIGN_TYPES.SCREEN && isNewContent === 1);
        };

        /**
         * @param {string} tag
         */
        $scope.onTagClick = (tag) => {
            vm.data.onTagClick && vm.data.onTagClick(tag);
        }

        $scope.getTags = (item) => {
            return vm.data.tags && vm.data.tags(item) || [];
        }

        $scope.openActionsMenu = function($mdMenu, ev) {
            $mdMenu.open(ev);
        };

        $scope.isDropdownActionVisible = (btn, item) => {
            return !btn.divider && (!btn.isVisible || btn.isVisible(btn, item));
        }

        init();

        /**
         * Handler on items count change
         */
        function changeItemsCountHandler() {
            if (vm.counter < (vm.currentPage - 1) * vm.itemsPerPage + 1) {
                vm.currentPage = Math.ceil(vm.counter / vm.itemsPerPage) || 1;
            }
            vm.data.updateCallback(vm.currentPage, vm.itemsPerPage);
        }

        /**
         * Handles click on item
         */
        function itemClickHandler(item) {
            if (!vm.needRadiobutton && typeof vm.data.itemClickCallback === 'function') {
                vm.data.itemClickCallback(item);
            }
        }

        /**
         * Handles click on item
         */
        function clickRadiobuttonHandler($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            if (typeof vm.data.itemClickCallback === 'function') {
                vm.selectedItem = vm.selectedItem !== item ? item : false;
                vm.data.itemClickCallback(vm.selectedItem);
            }
        }

        /**
         * Handles click on checkbox
         * @param $event
         * @param item
         */
        function checkboxClickHandler($event, item) {
            $event.preventDefault(); // to prevent accordion expand/collapse
            $event.stopPropagation();
            if (angular.isArray(vm.selectedItems)) {
                const index = vm.selectedItems.indexOf(item);
                if (index === -1) {
                    vm.selectedItems.push(item);
                } else {
                    vm.selectedItems.splice(index, 1);
                }
            }

            if (angular.isFunction(vm.data.checkboxClickCallback)) {
                vm.data.checkboxClickCallback(item);
            }
        }

        // *
        //  * Checks if item is selected
        //  *
        //  * @param item
        //  * @returns {boolean}

        function isSelected(item) {
            return angular.equals(vm.selectedItem, item);
        }

        /**
         * Checks if item is selected
         *
         * @param item
         * @returns {boolean}
         */
        function isCheckboxSelected(item) {
            return angular.isArray(vm.selectedItems) && vm.selectedItems.includes(item);
        }

        /**
         * Refreshes list data
         *
         * @param {*} newValue
         * @param {*} oldValue
         */
        function refresh(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                vm.data.updateCallback(vm.currentPage, vm.itemsPerPage);
            }
        }

        // private methods

        /**
         * Initialization method
         */
        function init() {       
            vm.data.updateCallback(vm.currentPage, vm.itemsPerPage, true);

            $scope.$watch(angular.bind(vm, function(counter) {
                return this.counter;
            }), function(newValue, oldValue) {
                if (_.isNumber(oldValue) && newValue !== oldValue) {
                    vm.changeItemsCountHandler();
                }
            });

            $scope.$watch(angular.bind(vm, function(updateCounter) {
                return this.updateCounter;
            }), refresh);

            $scope.isDropdownMenuVisible = vm.data.buttons
                && vm.data.buttons.dropdownItems
                && vm.data.buttons.dropdownItems.length;
        }

        /**
         * Extracts value from item by key
         *
         * @param {object} item
         * @param {string} key
         * @param {number} index
         * @returns {string}
         */
        function getValue(item, key, index) {
            if (!item || !_.isObject(item) || !key) {
                return "";
            }
            return String(_.isString(key) && item[key] ? item[key] :
                        _.isFunction(key) ? key(item, index) : "");
        }

        /**
         * Defines (dynamically or statically) class name for button
         *
         * @param {object} btn
         * @param {object} item
         * @returns {string} Class name that will be applied to a button
         */
        function defineClassForButton(btn, item) {
            if (typeof btn.class === 'function') {
                return btn.class(item);
            }

            return btn.class;
        }

        function isTranslate(column, item) {
            return angular.isFunction(column.translate)
                ? column.translate(item)
                : !!column.translate;
        }
    }
}());
