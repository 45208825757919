(function() {
    'use strict';

    angular.module('beacon.app')
        .component('userGroupDetailsTab', {
            templateUrl: '/assets/views/user-groups/new/tabs/user-group-details/user-group-details.tpl.html',
            controller: UserGroupDetailsController,
            bindings: {
                userGroupData: '<',
                form: '<'
            }
        });

    function UserGroupDetailsController(
        $scope,
        StatesFactory,
        APP_MODES,
        $mdConstant,
    ) {
        const $ctrl = this;

        $ctrl.state = StatesFactory.UserGroupStates;
        $ctrl.appModes = [];
        $ctrl.chipsSeparators = [
            $mdConstant.KEY_CODE.ENTER,
            $mdConstant.KEY_CODE.COMMA,
            $mdConstant.KEY_CODE.SEMICOLON,
        ];

        $ctrl.$onInit = () => {
            $ctrl.appModes = Object.values(APP_MODES);
        };

        function canNext() {
            $ctrl.state.canNext = $ctrl.form
                && $ctrl.form.$valid;
        }

        $scope.$watch(function() {
            return $ctrl.form && $ctrl.form.$valid;
        }, canNext);
    }
})();
