(function() {
    'use strict';

    angular.module('beacon.app').service('ContentTagsApiService',
        function(
            $http,
        ) {
            return {
                getAll,
                modify,
            };

            /**
             * @return {Promise<{id: number, name: string, contents_count: number}[]>}
             */
            function getAll() {
                return $http.get('/api/content-tags').then(response => response.data);
            }

            /**
             * @param {BaseContentModel} content
             * @param {string[]} tags
             * @return {Promise<{id: number, name: string}[]>} - updated list of content tags
             */
            function modify(content, tags) {
                return $http
                    .post(`/api/content-tags/modify/${content.id}`, { tags })
                    .then(response => response.data);
            }
        });
})();
