(function() {
    'use strict';

    angular.module('beacon.app')
        .component('audienceUserProfilesPicker', {
            controller: AudienceUserProfilesPicker,
            templateUrl: '/assets/views/common/directives/audience-picker/audience-user-profiles-picker/audience-user-profiles-picker.tpl.html',
            bindings: {
                value: '=',
            },
        });

    /**
     * @property {number[]} value
     * @constructor
     */
    function AudienceUserProfilesPicker(
        $scope,
        ShareParkDataService,
        TranslateService,
    ) {
        const $ctrl = this;
        const translate = TranslateService.getCurrentUiLanguageTranslation;

        $scope.userProfiles = [];
        $scope.loaded = false;

        $ctrl.$onInit = () => {
            _loadUserProfiles()
                .then(() => $scope.loaded = true);
        }

        $scope.isActive = (userProfile) => {
            return $ctrl.value.includes(userProfile.id);
        }

        $scope.onClick = (userProfile) => {
            const index = $ctrl.value.findIndex(id => id === userProfile.id);

            if (index >= 0) {
                $ctrl.value.splice(index, 1);
            } else {
                $ctrl.value.push(userProfile.id);
            }
        }

        function _loadUserProfiles() {
            return ShareParkDataService.getUserProfiles()
                .then(response =>
                    $scope.userProfiles = response.plain().sort(
                        (a, b) => translate(a.name).localeCompare(translate(b.name))
                    )
                );
        }
    }
})();
