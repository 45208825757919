(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenHealthChart', {
            controller: ScreenHealthChartController,
            templateUrl: '/assets/views/location/dashboard/screen-health-chart/screen-health-chart.tpl.html',
        });

    function ScreenHealthChartController(
        $q,
        $http,
        $scope,
        $moment,
        $interval,
    ) {
        const $ctrl = this;
        const REFRESH_INTERVAL_MS = 60000;
        let refreshInterval;

        $ctrl.periods = ['1m', '5m', '10m', '30m', '1h'];

        $ctrl.filters = {
            from: $moment().add(-1, 'day').set({ s: 0, ms: 0 }).toDate(),
            to: $moment().add(1, 'year').set({ s: 0, ms: 0 }).toDate(),
            period: '1m',
        };

        $ctrl.$onInit = () => {
            _waitLibrary()
                .then(() => {
                    _initLibrary();
                    refreshInterval = $interval(_drawChart, REFRESH_INTERVAL_MS);
                });
        }

        $scope.$on('$destroy', function() {
            if (refreshInterval) {
                $interval.cancel(refreshInterval);
            }
        });

        $scope.drawChart = _drawChart;

        function _onFilterChanged() {
            _drawChart();
        }

        function _onReady() {
            _drawChart();
            _setFilterWatcher();
        }

        function _drawChart() {
            const dataTable = new google.visualization.DataTable();

            dataTable.addColumn('datetime', 'X');
            dataTable.addColumn('number', 'Green');
            dataTable.addColumn('number', 'Red');
            dataTable.addColumn('number', 'Yellow');

            const options = {
                // hAxis: {
                //     format: 'dd/MM/yyyy HH:mm'
                // },
                vAxis: {
                    viewWindow: {
                        max: 0, // will be set later...
                    },
                    // scaleType: 'log'
                },
                chartArea: {
                    width: '90%',
                    height: '70%',
                },
                legend: {
                    position: 'bottom'
                },
                series: {
                    0: { color: '#00b003' },
                    1: { color: '#ec453f' },
                    2: { color: '#e7ad1b' },
                },
                focusTarget: 'category',
                backgroundColor: '#fafafa',
            };

            const chart = new google.visualization.LineChart(document.getElementById('chart'));
            // const dateFormatter = new google.visualization.DateFormat({pattern: 'dd/MM/yyyy HH:mm'});
            // dateFormatter.format(dataTable, 0);

            _getChartData().then(data => {
                const max = _getMaxFromDataset(data);

                options.vAxis.viewWindow.max = 1.2 * max;
                dataTable.addRows(data)
                chart.draw(dataTable, options);
            });
        }

        function _initLibrary() {
            google.charts.load('current', {packages: ['corechart', 'line']});
            google.charts.setOnLoadCallback(_onReady);
        }

        function _getChartData() {
            const params = {
                from: $ctrl.filters.from.toISOString(),
                to: $ctrl.filters.to.toISOString(),
                period: $ctrl.filters.period,
            };

            return $http.get(
                '/api/screen-statuses?' + new URLSearchParams(params).toString()
            )
            .then(response => {
                const stats = response.data;
                return stats.map(stat => {
                    return [
                        $moment.utc(stat.date).toDate(),
                        stat.green,
                        stat.red,
                        stat.yellow,
                    ]
                });
            });
        }

        function _waitLibrary() {
            return $q(resolve => {
                const interval = $interval(() => {
                    if (google.charts) {
                        $interval.cancel(interval);
                        resolve();
                    }
                }, 200);
            })
        }

        function _setFilterWatcher() {
            $scope.$watch(
                () => $ctrl.filters,
                (prev, next) => {
                    const isChanged = ['from', 'to', 'period']
                        .map(key => prev[key] !== next[key])
                        .filter(isChanged => isChanged)
                        .length > 0;

                    if (isChanged) {
                        _onFilterChanged();
                    }
                },
                true,
            );
        }

        function _getMaxFromDataset(dataset) {
            return Math.max(
                ...dataset.map(
                    row => Math.max(
                        ...row.filter(
                            val => Number.isInteger(val)
                        )
                    )
                )
            );
        }
    }
}());
