(function() {
    'use strict';

    angular.module('beacon.app')
        .component('modifyTagsForm', {
            templateUrl: '/assets/views/common/components/content-tags/modify-tags-form/modify-tags-form.tpl.html',
            controller: ModifyTagsForm,
            bindings: {
                tags: '<',
            }
        });

    /**
     * @param $scope
     * @param $timeout
     * @param $mdConstant
     * @param {ContentTagsApiService} ContentTagsApiService
     *
     * @property {string[]} tags
     * @property {function({tags: string[]})} onChanged
     * @constructor
     */
    function ModifyTagsForm(
        $scope,
        $timeout,
        $mdConstant,
        ContentTagsApiService,
    ) {
        const $ctrl = this;

        $ctrl.all = [];

        $ctrl.chipsSeparators = [
            $mdConstant.KEY_CODE.ENTER,
            $mdConstant.KEY_CODE.SEMICOLON,
            $mdConstant.KEY_CODE.COMMA
        ];

        $ctrl.$onInit = () => {
            _loadTags();
        }

        /**
         * Search for tags.
         */
        $ctrl.querySearch = (query) => {
            const lowercaseQuery = query.toLowerCase();

            return query
                ? $ctrl.all.filter(item => item.toLowerCase().indexOf(lowercaseQuery) !== -1)
                : [];
        }

        function _loadTags() {
            ContentTagsApiService.getAll().then(tags => {
                $ctrl.all = tags.map(item => item.name);
            })
        }
    }
})();
