(function() {
    'use strict';

    angular.module('beacon.app')
        .directive('audiencePicker', audiencePicker);

    function audiencePicker() {
        return {
            restrict: 'AE',
            templateUrl: '/assets/views/common/directives/audience-picker/audience-picker.tpl.html',
            replace: true,
            controller: AudiencePickerController,
            controllerAs: '$ctrl',
            bindToController: true,
            scope: {
                audiencesData: '=audiencePicker',
                storageName: '<',
                pickerHeader: '<?',
                currentSegmentationGroupId: '<?',
                hideRandom: '<?',
                disabled: '<?',
            }
        };
    }

    function AudiencePickerController(
        $element,
        $rootScope,
        $scope,
        $timeout,
        AudiencesService,
        CampaignDataService,
        PermissionsService,
        SegmentDataService,
        StorageFactory,
        SEGMENT_TYPES,
        AUDIENCE_LOCATION_TYPES,
    ) {
        const vm = this;

        const MSEC_PER_SEC = 1000;

        let STORAGE = StorageFactory.Storage(vm.storageName);
        const {
            getLimits,
            updateSlider,
            decodeFilterValueParams,
            isRandomAudienceTypeSelected,
            isDynamicRangeType,
            removeTitlePreferenceValues,
        } = AudiencesService;

        // public properties
        vm.audienceOptions = [];
        vm.recipientsCount = null;
        vm.sliderOptions = {};

        vm.recipientsCountUpdateTimer = null;

        vm.SEGMENT_TYPES = SEGMENT_TYPES;
        vm.AUDIENCE_LOCATION_TYPES = AUDIENCE_LOCATION_TYPES;

        init();

        // public methods
        vm.isCheckedAudienceOption = isCheckedAudienceOption;
        vm.isActiveAudienceStar = isActiveAudienceStar;
        vm.isDynamicRangeType = isDynamicRangeType;
        vm.isRandomAudienceTypeSelected = isRandomAudienceTypeSelected;
        vm.toggleAudienceOption = toggleAudienceOption;
        vm.toggleAudienceRange = toggleAudienceRange;
        vm.toggleAudienceStar = toggleAudienceStar;
        vm.generateRatingRange = generateRatingRange;
        vm.generateAudienceTitle = generateAudienceTitle;
        vm.getFilterValueIndex = getFilterValueIndex;
        vm.getFilledAudiencesData = getFilledAudiencesData;
        vm.getAudienceOptions = getAudienceOptions;
        vm.getLimits = getLimits;
        vm.getRandomizationTime = getRandomizationTime;
        vm.getRandomAudienceData = getRandomAudienceData;
        vm.getSelectedFilterValues = getSelectedFilterValues;
        vm.initSliderData = initSliderData;
        vm.onRangeInputBlur = onRangeInputBlur;
        vm.isValidRange = isValidRange;
        vm.delayedUpdateRecipientsCount = delayedUpdateRecipientsCount;
        vm.randomizeRandomAudienceFilter = randomizeRandomAudienceFilter;

        vm.geofenceTypePermission = PermissionsService.generateGeofenceSegmentTypesPermission;

        $scope.isShareParkCarParksType = (audience) => {
            return audience.filterValues[0].filterValueParams.value === '1';
        }

        $scope.isShareParkUserProfilesType = (audience) => {
            return audience.filterValues[0].filterValueParams.value === '0';
        }

        // private methods

        /**
         * Returns audience option is checked
         *
         * @param {number} optionId
         * @param {number} audienceId
         *
         * @returns {boolean}
         */
        function isCheckedAudienceOption(optionId, audienceId) {
            const audience = getFilledAudiencesData()[audienceId] || vm.audiencesData[audienceId];
            const audienceValues = audience.audienceValues;
            return audienceValues && audienceValues.indexOf(optionId) !== -1;
        }

        /**
         * Returns audience star is checked
         *
         * @param {number} audienceId
         * @param {number} filterValueId
         * @param {number} starIndex
         * @returns {boolean}
         */
        function isActiveAudienceStar(audienceId, filterValueId, starIndex) {
            const audienceStarsAll = getFilledAudiencesData()[audienceId].audienceStars;

            return Object.keys(audienceStarsAll).indexOf(filterValueId.toString()) !== -1
                && Array.isArray(audienceStarsAll[filterValueId])
                && audienceStarsAll[filterValueId].indexOf(starIndex) !== -1
        }

        /**
         * Returns data which should be displayed on targeting page
         * for simple audiences that should be just audiences data, but for random segmentation category
         * that should be predefined data of this category
         *
         * @return {*}
         */
        function getFilledAudiencesData() {
            if (isRandomAudienceTypeSelected(vm.audiencesData)) {
                return vm.audiencesData[getRandomAudienceId()].preselectedAudiencesData;
            } else {
                return vm.audiencesData;
            }
        }

        /**
         * Returns randomization time of selected random audience
         * @return {null | string}
         */
        function getRandomizationTime() {
            const randomSegmentKey = getRandomAudienceId();
            let randomizationTime = vm.audiencesData[randomSegmentKey] ?
                vm.audiencesData[randomSegmentKey].randomizationTime : null;

            return randomizationTime ? randomizationTime.format('YYYY-MM-DD HH:mm:ss') : null;
        }

        /**
         * Returns random audience id
         * @return string | null
         */
        function getRandomAudienceId() {
            return Object.keys(vm.audiencesData)[0];
        }

        function getAudienceOptions() {
            return isRandomAudienceTypeSelected(vm.audiencesData) ? vm.allAudienceOptions : vm.audienceOptions;
        }

        function getRandomAudienceData() {
            return vm.audienceOptions.find(audience => {
                return audience.id === parseInt(getRandomAudienceId());
            })
        }

        function randomizeRandomAudienceFilter() {
            const randomAudienceId = getRandomAudienceId();
            SegmentDataService.randomizeSegmentByCategoryRef(randomAudienceId).then(response => {
                vm.audiencesData[randomAudienceId].randomizationTime = moment(moment.utc(response).toDate()).local();
            })
        }

        /**
         * Generates audience title
         * @param {object} audience
         * @returns {string}
         */
        function generateAudienceTitle(audience) {
            if (angular.isDefined(audience)) {
                return (audience.description && angular.isString(audience.description)) ? audience.description : audience.label;
            } else {
                return '';
            }
        }

        /**
         * Returns elementIndex for sorting
         * @param filterValue
         * @return {number}
         */
        function getFilterValueIndex(filterValue) {
            return parseInt(filterValue.elementIndex);
        }

        /**
         *
         * @param audience
         * @return []
         */
        function getSelectedFilterValues(audience) {
            return audience.filterValues.filter(filter =>
                isCheckedAudienceOption(filter.id, audience.id)
            );
        }

        /**
         * Initialize data for dynamic range slider
         * @param {object} audience
         */
        function initSliderData(audience) {
            let rangesData = getFilledAudiencesData()[audience.id].rangesData;
            let limits = getLimits(audience);
            vm.sliderOptions[audience.id] = {
                floor: limits.minValue > rangesData.minValue ? rangesData.minValue : limits.minValue,
                ceil: limits.maxValue > rangesData.maxValue ? limits.maxValue : rangesData.maxValue,
                step: 1,
                onChange: toggleAudienceRange,
                disabled: vm.disabled,
                extendable: limits.editable
            };
        }

        function isValidRange(audience) {
            return getFilledAudiencesData()[audience.id].rangesData.maxValue >= getFilledAudiencesData()[audience.id].rangesData.minValue;
        }

        /**
         * Handles range min-max inputs blur event
         * @param {object} audience
         */
        function onRangeInputBlur(audience) {

            // change slider max value according to  range max value
            if (vm.audiencesData[audience.id].rangesData.maxValue > vm.sliderOptions[audience.id].ceil) {
                vm.sliderOptions[audience.id].ceil = vm.audiencesData[audience.id].rangesData.maxValue;
            }
            if (vm.audiencesData[audience.id].rangesData.maxValue < vm.audiencesData[audience.id].rangesData.minValue) {
                vm.audiencesData[audience.id].rangesData.maxValue = vm.audiencesData[audience.id].rangesData.minValue;
            }

            // if min range value is less than slider min value
            if (vm.audiencesData[audience.id].rangesData.minValue < vm.sliderOptions[audience.id].floor) {
                vm.sliderOptions[audience.id].floor = vm.audiencesData[audience.id].rangesData.minValue;
            }
        }

        /**
         * Toggles audience option
         *
         * @param {number} optionId
         * @param {number} audienceId
         */
        function toggleAudienceOption(optionId, audienceId) {
            if (!vm.audiencesData[audienceId].audienceValues) {
                vm.audiencesData[audienceId].audienceValues = [];
            }
            const audienceValues = vm.audiencesData[audienceId].audienceValues;
            const index = audienceValues.indexOf(optionId);
            if (index === -1) {
                audienceValues.push(optionId);
            } else {
                audienceValues.splice(index, 1);
                delete vm.audiencesData[audienceId].preferenceData[optionId];
            }

            delayedUpdateRecipientsCount();
        }

        /**
         * Toddles audience range option
         * @param {object} audience
         */
        function toggleAudienceRange(audience) {
            delayedUpdateRecipientsCount();
        }

        /**
         * Toggles audience star
         *
         * @param {number} audienceId
         * @param {number} filterValueId
         * @param {number} starIndex
         */
        function toggleAudienceStar(audienceId, filterValueId, starIndex) {
            const audienceStars = vm.audiencesData[audienceId].audienceStars;

            if (!audienceStars[filterValueId]) {
                audienceStars[filterValueId] = [starIndex];
            } else {
                const index = audienceStars[filterValueId].indexOf(starIndex);
                if (index === -1) {
                    audienceStars[filterValueId].push(starIndex);
                } else {
                    audienceStars[filterValueId].splice(index, 1);
                }
            }

            if (!audienceStars[filterValueId].length) {
                delete audienceStars[filterValueId];
            }

            delayedUpdateRecipientsCount();
        }

        function delayedUpdateRecipientsCount() {
            if (vm.recipientsCountUpdateTimer) {
                $timeout.cancel(vm.recipientsCountUpdateTimer);
                vm.recipientsCountUpdateTimer = null;
            }
            vm.recipientsCountUpdateTimer = $timeout(loadRecipientsCount, 5 * MSEC_PER_SEC);
        }

        function loadRecipientsCount() {
            CampaignDataService.getRecipientsCount({
                filters: JSON.stringify(vm.audiencesData)
            })
                .then((response) => {
                    vm.recipientsCount = STORAGE.set('recipientsCount', response.recipientsCount);
                })
                .catch(console.error.bind(console));
        }

        /**
         * Generates an array of stars for loop
         *
         * @param {number} ratingRange
         * @returns {array}
         */
        function generateRatingRange(ratingRange) {
            const rangeArray = [];
            for (let i = 1; i <= Number(ratingRange); i++ ) {
                rangeArray.push(i);
            }
            return rangeArray;
        }

        function resizeSegmentsList() {
            const minHeight = 550;
            const segmentsList = $element[0].querySelector('.segmentationCategoryFilter .scroller');
            const viewportOffset = segmentsList.getBoundingClientRect();
            const neededHeight = window.innerHeight - viewportOffset.top - 10;
            segmentsList.style.maxHeight = (neededHeight > minHeight ? neededHeight : minHeight) + 'px';
        }

        /**
         * Initialization method
         */
        function init() {
            vm.recipientsCount = STORAGE.get('recipientsCount');
            if (!vm.pickerHeader) {
                vm.pickerHeader = 'DETERMINE_TARGET_AUDIENCE';
            }

            CampaignDataService.getCampaignsAudience(vm.storageName)
                .then((audienceData) => {
                    vm.allAudienceOptions = decodeFilterValueParams(audienceData);
                    if(vm.hideRandom) {
                        vm.allAudienceOptions = vm.allAudienceOptions.filter(audienceOption => {
                            return audienceOption.categoryType !== SEGMENT_TYPES.RANDOM;
                        })
                    }
                    removeTitlePreferenceValues(vm.allAudienceOptions);
                    vm.audienceOptions = vm.allAudienceOptions;

                    if (isRandomAudienceTypeSelected(vm.audiencesData)) {
                        const randomAudienceData = getRandomAudienceData();
                        if (randomAudienceData) {
                            vm.audiencesData[getRandomAudienceId()].randomizationTime =
                                moment(moment.utc(randomAudienceData.filterValues[0].filterValueParams.randomizationTime).toDate()).local();
                            vm.disabled = true;
                        } else {
                            vm.audiencesData = {};
                        }
                    }
                    loadRecipientsCount();
                    resizeSegmentsList();
                    $scope.$apply();
                    updateSlider();
                })
                .catch(console.error.bind(console));
            SegmentDataService.getSegmentationGroups()
                .then((response) => {
                    vm.segmentationGroups = response.plain();
                    vm.segmentationGroups.forEach(group => {
                        group.message = angular.fromJson(group.message);
                    });

                    if (angular.isDefined(vm.currentSegmentationGroupId)) {
                        vm.segmentationGroups = vm.segmentationGroups.filter(group => group.id !== vm.currentSegmentationGroupId);
                    }
                })
                .catch(() => {
                    vm.segmentationGroups = [];
                });
        }
    }
}());
