(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newScreen', {
            controller: NewScreenController,
            templateUrl: '/assets/views/location/new-screen/new-screen.tpl.html',
        });

    /**
     * @param $q
     * @param $state
     * @param $stateParams
     * @param $scope
     * @param locationTabState
     * @param LineRouteDataService
     * @param LocationDataService
     * @param ModelsFactory
     * @param StatesFactory
     * @param ScreenHelper
     * @param ScreenDataService
     * @param StorageFactory
     * @param GoogleMapsUtilitiesService
     * @param GoogleMapsRequestService
     * @param TIMETABLE_OPTIONS
     * @param TRACK_FILTERS_SPECIAL_VALUES
     * @param MgateService
     * @param {MultiStationsHelper} MultiStationsHelper
     * @param {ScreenControllerHelper} ScreenControllerHelper
     * @constructor
     */
    function NewScreenController(
        $q,
        $state,
        $stateParams,
        $scope,
        locationTabState,
        LineRouteDataService,
        LocationDataService,
        ModelsFactory,
        StatesFactory,
        ScreenHelper,
        ScreenDataService,
        StorageFactory,
        GoogleMapsUtilitiesService,
        GoogleMapsRequestService,
        TIMETABLE_OPTIONS,
        TRACK_FILTERS_SPECIAL_VALUES,
        MgateService,
        MultiStationsHelper,
        ScreenControllerHelper,
    ) {
        const vm = this;
        const STORAGE = StorageFactory.Storage('Main');

        vm.state = StatesFactory.ScreenStates.refresh();
        vm.distribute = {
            allSubdomains: false,
        };
        vm.stationDepartures = [];
        vm.lines = [];
        vm.tracks = [];
        vm.unity3dModelId = null;

        /**
         * @type {Object<string, string>[]}
         */
        vm.stationNameReplacementMap = []; // i.e. [{"(Berlin)":""}]

        const { createFormData } = ScreenHelper;
        const { getLocationDataByCoordinates } = GoogleMapsUtilitiesService;
        const { getTimezoneByCoordinates } = GoogleMapsRequestService;
        const { getDepartureTimes, getDeparturesTracks } = LineRouteDataService;

        vm.onScreenGroupsChange = onScreenGroupsChange;
        vm.onFindStationCallback = onFindStationCallback;
        vm.fillLocationData = fillLocationData;
        vm.onStationIdChange = onStationIdChange;

        vm.$onInit = init;

        /**
         * Initialization method
         */
        function init() {
            vm.tenantId = STORAGE.currentUserInfo.subdomain.tenant_id;
            vm.subdomainId = STORAGE.currentUserInfo.subdomain.id;
            vm.state.type = $stateParams.type;
            vm.isEditMode = vm.state.type === "edit";
            vm.controllerData = vm.isEditMode ?
                $stateParams.data : new ModelsFactory.ScreenControllerDetails();

            const controllerId = vm.controllerData.id;
            if (controllerId) {
                LocationDataService.getControllerLocation(controllerId)
                    .then(location => {
                        const data = angular.copy(location.data);
                        vm.controllerData.timetableOptions = data && data.timetableOptions || {};
                        vm.unity3dModelId = location.unity3dModelId;
                    });
            }

            vm.controllerData.station.stopId && updateStationData(vm.controllerData.station.stopId);
            _initReplacementMap().then(() => {
                _initBackwardCompatibility();
            });
        }

        function onFinish() {
            const distribute = vm.isEditMode ? null : vm.distribute;
            const formData = createFormData(vm.controllerData, distribute, vm.selectedScreenGroups);

            vm.oldControllerImage && formData.append('oldControllerImage', vm.oldControllerImage);
            vm.oldScreenImage && formData.append('oldScreenImage', vm.oldScreenImage);
            formData.append('unity3dModelId', vm.unity3dModelId);

            const success = () => $state.go($stateParams.previousState, {
                paginationData: $stateParams.paginationData,
                data: {
                    selectedTabIndex: locationTabState.views.LOCATIONS.tabs.SCREENS.index,
                    selectedSubtabIndex: locationTabState.views.LOCATIONS.tabs.SCREENS
                        .subtabs.SCREEN_ITEMS.index,
                },
            });
            const error = console.error.bind(console);

            ScreenDataService.setScreen(formData)
                .then(success)
                .catch(error)
        }

        function onFindStationCallback({ id, name, extId, lat, lon, productAtStop}) {
            clearTimetableOptions();
            const data = vm.controllerData;

            data.name = name;
            data.controllerLabel = ScreenControllerHelper.generateControllerLabel(name, vm.stationNameReplacementMap);
            data.screen.purpose = name;

            data.station = {
                stopName: name,
                stopId: extId,
                lat: `${lat}`,
                lng: `${lon}`,
                latlng: `${lat},${lon}`,
                productsAtStation: productAtStop.map(product => product.lineId).join(','),
            };

            if (!data.latlng) {
                data.latlng = data.station.latlng;
                vm.fillLocationData({
                    latitude: Number(data.station.lat),
                    longitude: Number(data.station.lng)
                });
            }
            data.screen.latlng = data.screen.latlng || data.station.latlng;
            vm.lines = productAtStop.map(line => line.name).filter(_onlyUnique);

            updateDepartureTimes(extId);
        }

        function fillLocationData(coords) {
            $q.all([
                getLocationDataByCoordinates(coords),
                getTimezoneByCoordinates({ location: vm.controllerData.latlng}),
            ]).then(([locationData, timeZoneId]) => {
                Object.assign(vm.controllerData, locationData);
                vm.controllerData.locationTimeZoneId = timeZoneId;
            });
        }

        function onStationIdChange(stationId) {
            clearTimetableOptions();
            stationId && updateStationData(stationId);
        }

        /**
         * Clears timetable options which depend on station
         */
        function clearTimetableOptions() {
            const timetableOptions = vm.controllerData.timetableOptions;
            if (timetableOptions) {
                [
                    TIMETABLE_OPTIONS.journeyNameFilter,
                    TIMETABLE_OPTIONS.platformFilter,
                    TIMETABLE_OPTIONS.direction,
                    TIMETABLE_OPTIONS.poleFilter,
                ].forEach(filter => {
                    timetableOptions[filter.prop] = angular.copy(filter.defaultValue);
                })
            }
            vm.tracks = [];
            vm.lines = [];
            vm.controllerData.locationTrack = '';
        }

        function updateStationData(stationId) {
            ScreenDataService.getStationById(stationId)
                .then(({station}) => {
                    vm.lines = station.productAtStop.map(line => line.name).filter(_onlyUnique);
                }).catch(err => {
                vm.lines = [];
            });
            updateDepartureTimes(stationId);
        }

        function updateDepartureTimes(stationId) {
            getDepartureTimes(stationId, vm.tenantId).then(response => {
                vm.stationDepartures = response.data.Departure || [];
                const tracks = getDeparturesTracks(vm.stationDepartures);
                const trackFilterSpecialValues = Object.values(TRACK_FILTERS_SPECIAL_VALUES)
                    .map(filter => filter.value);
                vm.tracks = trackFilterSpecialValues.concat(tracks)
                    .map(track => track.toString())
                    .filter(_onlyUnique);
            }).catch((err) => {
                console.error(err);
                vm.stationDepartures = [];
            })
        }

        function onScreenGroupsChange(selectedScreenGroups) {
            vm.selectedScreenGroups = selectedScreenGroups;
        }

        function _onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }

        function _initReplacementMap() {
            return MgateService.productParams(vm.subdomainId)
                .then((params = {}) => {
                    vm.stationNameReplacementMap = params.stationNameReplacementMap;
                });
        }

        /**
         * From single to multi station mode
         *
         * @private
         */
        function _initBackwardCompatibility() {
            $scope.$watch(
                () => [
                    vm.controllerData.station,
                    vm.controllerData.controllerLabel
                ],
                /**
                 * @param {Station} station
                 * @param {string} label
                 */
                ([station, label]) => {
                    if (!station) {
                        return;
                    }

                    if (!vm.controllerData.locationStationIds) {
                        vm.controllerData.locationStationIds = {};
                    }

                    vm.controllerData.locationStationId = station.stopId;
                    vm.controllerData.locationStationIds[1] = {
                        ...MultiStationsHelper.makeSubStation(station, vm.stationNameReplacementMap),
                        label,
                    };
                },
                true,
            );
        }

        $scope.$watch(
            () => vm.state.finish,
            newValue => newValue && onFinish());
    }
})();
