(function() {
    'use strict';

    angular.module('beacon.app').service('ContentDataService',
        function(
            $http,
            Restangular
        ) {
            return {
                contents,
                content,
                create,
                update,
                deleteContent,
                contentTypes,
                contentGroup,
                contentGroups,
                createContentGroup,
                deleteContentGroup,
                updateContentGroup,
                getContentGroupIcons,
                getContentForInternalApp,
                getRewardTypes,
                preparePreview,
                uploadInteractive,
                upload,
                uploadVideo,
                deleteImages,
                uploadCategoryImage,
                getRSS,
                getBase64,
                getBannerConfigs,
                getBannerConfigsWithInactive,
                getAllBannerConfigs,
                setBannerConfigs,
                getContentConfigs,
                getExternalTimetables,
                getRssWebSources,
                getContentsCount,
                getContentsForTour,
                isPdfURL,
                getRelatedBanners,
                getFeedbackEvents,
                pin,
            };

            // Public methods
            /**
             * Returns content list
             *
             * @param {{contentTypeId?: string, contentId?: string} | undefined} data
             */
            function contents(data) {
                return Restangular.all('contents').getList(data);
            }

            /**
             * Returns simple content by it`s id
             *
             * @param {object} id
             */
            function content(id) {
                return Restangular.one('contents', id).get();
            }

            /**
             * Creates content
             *
             * @param {object} data
             */
            function create(data) {
                return Restangular.all('contents')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, {'Content-Type': undefined});
            }

            /**
             * Updates content
             *
             * @param {object} content
             * @param {object} data
             */
            function update(content, data) {
                return content.customPOST(data, '', {}, { 'Content-Type': undefined, '_method': 'PUT' });
            }

            /**
             * Deletes content by it`s id
             *
             * @param {object} id
             */
            function deleteContent(id) {
                return Restangular.one('contents', id).remove();
            }

            /**
             * Returns content types list
             */
            function contentTypes(data) {
                return Restangular.all('contents/types').getList(data);
            }

            /**
             * Returns content groups list
             *
             * @param {object} data
             * @returns {Promise}
             */
            function contentGroups(data) {
                return Restangular.all('contents/groups').getList(data);
            }

            /**
             * Returns content group by ID
             *
             * @param {number} id
             * @returns {Promise}
             */
            function contentGroup(id) {
                return Restangular.one('contents/groups', id).get();
            }

            /**
             * Creates new content group
             *
             * @param {object} data
             * @returns {Promise}
             */
            function createContentGroup(data) {
                return Restangular.all('contents/groups')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, { 'Content-Type': undefined });
            }

            /**
             * Updates content group
             *
             * @param {object} contentGroup
             * @param {object} data
             * @returns {Promise}
             */
            function updateContentGroup(contentGroup, data) {
                return contentGroup.customPOST(data, '', {}, { 'Content-Type': undefined, '_method': 'PUT' });
            }

            /**
             * Removes content group by ID
             *
             * @param {number} id
             * @returns {Promise}
             */
            function deleteContentGroup(id) {
                return Restangular.one('contents/groups', id).remove();
            }

            /**
             * Returns list of content group icons
             *
             * @returns {Promise}
             */
            function getContentGroupIcons() {
                return Restangular.one('contents/groups/icons').getList();
            }

            /**
             * Returns list of rewards
             *
             * @returns {Promise}
             */
            function getRewardTypes() {
                return Restangular.all('contents/rewards').getList();
            }

            /**
             * @deprecated - use ImageApiService instead
             */
            function upload(data) {
                return Restangular.all('upload')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, {'Content-Type': undefined});
            }

            function uploadVideo(data) {
                return Restangular.all('uploadVideo')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, {'Content-Type': undefined});
            }

            function deleteImages(data) {
                return Restangular.all('deleteImages')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, {'Content-Type': undefined});
            }

            function preparePreview(data) {
                return Restangular.all('contents/prepareinteractivepreview')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, {'Content-Type': undefined});
            }

            function uploadInteractive(data) {
                return Restangular.all('contents/uploadinteractive')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, {'Content-Type': undefined});
            }

            function getContentForInternalApp() {
                return Restangular.all('contents/interactivecontentlist').getList();
            }

            function uploadCategoryImage(data) {
                return Restangular.all('contents/uploadcategoryimage')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(data, {}, {'Content-Type': undefined});
            }

            function getRSS(data) {
                return Restangular.all('contents/getrss').getList(data);
            }

            /**
             * @deprecated - wrong algorithm: does not support png, gif and svg (returns mimetype image/jpeg only)
             * @param {string} url
             * @return {*}
             */
            function getBase64(url) {
                return Restangular.one('contents/getbase64').get({url: url});
            }

            function getBannerConfigs() {
                return Restangular.all('contents/get-banner-configs').getList();
            }

            function getBannerConfigsWithInactive(tenantId) {
                return Restangular.all('contents/get-banner-configs-with-inactive/' + tenantId).getList();
            }

            function getAllBannerConfigs() {
                return Restangular.all('contents/get-all-banner-configs').getList();
            }

            function setBannerConfigs(configs, tenantId) {
                return $http.post('/api/contents/set-banner-configs/' + tenantId, configs)
            }

            function getContentConfigs() {
                return Restangular.all('contents/get-content-configs').getList();
            }

            /**
             * Returns list of external timetables (from 3rd party service)
             *
             * @returns {Promise}
             */
            function getExternalTimetables() {
                return  Restangular.all('contents/external-timetables').getList()
                    // TODO: THIS IS A WORKAROUND FIX (WAITING FOR ELMAR BACK FROM SICK LEAVE)
                    .then(response => {
                        response.forEach(item => {
                            item.url = item.url.replace('https://bvg.demo.hafas.de/mct/bvg/', 'https://bvg.demo.hafas.de/mct/');
                        });
                        return response;
                    });
            }

            function getRssWebSources() {
                return Restangular.all('contents/get-rss-web-sources').getList();
            }

            function getContentsCount(id) {
                return Restangular.one('contents/get-contents-count', id).get();
            }

            /**
             * Returns content which can be attached into tour list
             *
             * @param {object} data
             */
            function getContentsForTour(data) {
                return Restangular.all('contents/forTour').getList(data);
            }

            /**
             * Check external app URL content type
             * @param url
             */
            function isPdfURL(url) {
                const formData = new FormData();
                formData.append('url', url);
                return Restangular.all('contents/is-pdf-url')
                    .withHttpConfig({transformRequest: angular.identity})
                    .post(formData, {}, {'Content-Type': undefined});
            }

            /**
             * Loads related banners list
             * @param contentId
             * @return {*|{method, params, headers}|Array}
             */
            function getRelatedBanners(contentId) {
                return Restangular.all('banners/related').getList({ contentId });
            }

            function getFeedbackEvents(feedbackExtId) {
                return $http.get(`/api/contents/feedback-events/${feedbackExtId}`).then(response => response.data);
            }

            function pin(content) {
                return $http.post(`/api/contents/pin/${content.id}`);
            }
        });
})();
