(function() {
    'use strict';

    angular.module('beacon.app')
        .component('predefinedSegmentType', {
            templateUrl: '/assets/views/campaign/segment/new/types/predefined/predefined.tpl.html',
            controller: PredefinedSegmentType,
            bindings: {
                langArray: '<',
                segmentData: '=',
            }
        });

    function PredefinedSegmentType(
        $scope,
        segmentAddState,
        SegmentDataService,
        SEGMENT_TYPES,
        SegmentHelper,
        SEGMENT_STATUS,
    ) {
        const vm = this;

        // public methods
        vm.changeSegmentStatus = SegmentHelper.changeSegmentStatus;
        vm.selectPredefinedSegmentCallback = selectPredefinedSegmentCallback;

        // Definition
        vm.state = segmentAddState;
        vm.PREDEFINED_TYPE = SEGMENT_TYPES.PREDEFINED;
        vm.EXT_PARAMS_TYPE = SEGMENT_TYPES.EXTERNAL_PARAMETERS;
        vm.PREDEFINED_RANGES = SEGMENT_TYPES.PREDEFINED_RANGES;

        init();

        $scope.isFilterParamsPickerVisible = () => {
            if (!vm.selectedPredefinedSegment) {
                return false;
            }

            return [
                vm.PREDEFINED_TYPE,
                vm.EXT_PARAMS_TYPE
            ].includes(vm.selectedPredefinedSegment.segmentType)
        }

        $scope.isRangesPickerVisible = () => {
            if (!vm.selectedPredefinedSegment) {
                return false;
            }

            return vm.selectedPredefinedSegment.segmentType === vm.PREDEFINED_RANGES
        }
        /**
         * Handles select predefined segment
         */
        function selectPredefinedSegmentCallback() {
            vm.segmentData.title = vm.selectedPredefinedSegment.name;
            vm.segmentData.description = vm.selectedPredefinedSegment.description || vm.selectedPredefinedSegment.name;
            vm.segmentData.definitionRef = parseInt(vm.selectedPredefinedSegment.id);
            vm.segmentData.message.referenceCode = vm.selectedPredefinedSegment.referenceCode;
            vm.segmentData.message.predefinedSegmentType = parseInt(vm.selectedPredefinedSegment.segmentType);
            initRanges();
        }

        /**
         * Inits ranges data for preview
         */
        function initRanges() {
            vm.dynamicRangeLimits = vm.predefinedRanges = null;

            if (
                ![
                    SEGMENT_TYPES.PREDEFINED,
                    SEGMENT_TYPES.EXTERNAL_PARAMETERS,
                    SEGMENT_TYPES.SHARE_PARK
                ].includes(parseInt(vm.selectedPredefinedSegment.segmentType))
            ) {
                const ranges = vm.selectedPredefinedSegment.params.split('|');
                if (ranges.length && ranges.length === 1) {
                    const rangeLimits = ranges[0].split('-');
                    vm.segmentData.message.params = {
                        rangeLimits: {
                            minValue: parseInt(rangeLimits[0]),
                            maxValue: parseInt(rangeLimits[1]),
                            editable: false,
                            unitLabel: vm.selectedPredefinedSegment.unitLabel
                        }
                    };
                }
            } else {
                vm.filterParams = vm.selectedPredefinedSegment.params.split('|').map(filterParam => {
                    const decoded = filterParam.split(':');
                    return {
                        label: decoded[0],
                        value: decoded[1]
                    };
                });
                vm.segmentData.message.params = {
                    filterValues: vm.selectedPredefinedSegment.params
                };
            }
        }

        // private methods

        /**
         * Initialization method
         */
        function init() {
            let neededTypes;

            switch (vm.segmentData.segment_type_id) {
                case SEGMENT_TYPES.SALES:
                    neededTypes = [SEGMENT_TYPES.SALES];
                    break;
                case SEGMENT_TYPES.EXTERNAL_PARAMETERS:
                    neededTypes = [SEGMENT_TYPES.EXTERNAL_PARAMETERS];
                    break;
                case SEGMENT_TYPES.SHARE_PARK:
                    neededTypes = [SEGMENT_TYPES.SHARE_PARK];
                    break;
                default:
                    neededTypes = [SEGMENT_TYPES.PREDEFINED, SEGMENT_TYPES.PREDEFINED_RANGES];
            }

            SegmentDataService.getPredefinedSegments({types: angular.toJson(neededTypes)}).then((response) => {
                vm.predefinedSegments = response.plain();
                if(!!vm.segmentData.definitionRef) {
                    vm.selectedPredefinedSegment = vm.predefinedSegments.find(predefinedSegment => parseInt(predefinedSegment.id) === parseInt(vm.segmentData.definitionRef));
                    initRanges();
                }
            });
            vm.activateSegment = vm.segmentData.status === SEGMENT_STATUS.ACTIVE;
        }

        function onFinish(event, option) {
            if (vm.segmentData.status === SEGMENT_STATUS.ACTIVE) {
                vm.segmentData.message.wasActivated = true;
            }
            if (typeof option.callback === 'function') {
                option.callback({
                    type: vm.state.type,
                    segmentData: angular.copy(vm.segmentData)
                });
            }
        }

        function canFinish() {
            return !!vm.segmentData.title
                && !!vm.segmentData.description
                && !!vm.segmentData.definitionRef
        }

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.title;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        $scope.$watch(angular.bind(vm, function() {
            return vm.segmentData.description;
        }), function() {
            vm.state.canFinish = canFinish();
        });

        /**
         * Listeners
         */
        $scope.$on('segment-finish', onFinish);
    }
})();
