(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screenStationPicker', {
            templateUrl: '/assets/views/common/components/screen-station-picker/screen-station-picker.tpl.html',
            controller: ScreenStationPickerController,
            bindings: {
                inputTitle: '@',
                labelTitle: '@',
                controllerData: '<',
                form: '<',
                onFindStationCallback: '<',
                onStationIdChange: '<',
            }
        });

    function ScreenStationPickerController(
        FindStationPopupService,
        ScreenHelper,
    ) {
        const vm = this;

        const { getScreenCoordinates } = ScreenHelper;

        vm.onFindStationClick = onFindStationClick;
        vm.onStationIdInputChange = onStationIdInputChange;

        /**
         * On find station click handler
         */
        function onFindStationClick() {
            FindStationPopupService.show(getScreenCoordinates(vm.controllerData, vm.form))
                .then(result => {
                    vm.onFindStationCallback(result);
                });
        }

        function onStationIdInputChange() {
            const stationData = vm.controllerData.station;
            delete stationData.lat;
            delete stationData.lng;
            delete stationData.latlng;
            delete stationData.productsAtStation;

            angular.isFunction(vm.onStationIdChange) && vm.onStationIdChange(stationData.stopId);
        }
    }
})();
