(function() {
    'use strict';

    angular
        .module('trmTimetableHafas')
        .service('MultiStationsHelper', function (ScreenControllerHelper) {
            return {
                getMultiStations,
                getMultiStationsName,
                deleteSubStation,
                canDeleteSubStation,
                canSupportMultiStations,
                makeSubStation,
            };

            /**
             * @param {ScreenController} controller
             * @return {ScreenControllerMultiStations}
             */
            function getMultiStations(controller) {
                return angular.isObject(controller.locationStationIds)
                    ? controller.locationStationIds
                    : angular.fromJson(controller.locationStationIds);
            }

            /**
             * Station name, based on multi-stations
             *
             * @param {ScreenController} controller
             * @param {number[]|undefined} stationsFilter
             */
            function getMultiStationsName(controller, stationsFilter) {
                const stations = getMultiStations(controller);
                const showAllStations = !angular.isArray(stationsFilter) || !stationsFilter.length;

                if (showAllStations) {
                    return Object
                        .values(stations)
                        .map(station => station && (station.label || station.name))
                        .filter(item => item)
                        .join(', ');
                }

                return stationsFilter
                    .map(index => stations[index] && (stations[index].label || stations[index].name))
                    .filter(item => item)
                    .join(', ');
            }

            /**
             * @param {ScreenController} controller
             * @param {number} index
             */
            function deleteSubStation(controller, index) {
                if (
                    controller.timetableOptions
                    && controller.timetableOptions.poleFilters
                    && controller.timetableOptions.poleFilters[index]
                ) {
                    delete controller.timetableOptions.poleFilters[index];
                }

                delete controller.locationStationIds[index];
            }

            /**
             * @param {ScreenController} controller
             * @param {number} index
             * @return {boolean}
             */
            function canDeleteSubStation(controller, index) {
                const subStations = controller.locationStationIds;

                if (!subStations) {
                    return false;
                }

                switch(index) {
                    case 2:
                        return Boolean(subStations[2]) && !subStations[3] && !subStations[4];
                    case 3:
                        return Boolean(subStations[3]) && !subStations[4];
                    case 4:
                        return Boolean(subStations[4]);
                }
            }

            /**
             * @param {ScreenController} controller
             * @return {boolean}
             */
            function canSupportMultiStations(controller) {
                return Boolean(getMultiStations(controller));
            }

            /**
             * @param {Station|StationV2} station
             * @param {Object<string, string>[]} replacements
             * @return {ScreenControllerMultiStation}
             */
            function makeSubStation(station, replacements) {
                const name = station.name || station.stopName;
                const id = station.extId || station.stopId;

                return {
                    name: ScreenControllerHelper.generateControllerLabel(name, replacements),
                    nameOrigin: name,
                    label: '',
                    id,
                }
            }
        });
})();
