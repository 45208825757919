(function() {
    'use strict';

    angular.module('scMeetings')
        .component('scMeetings', {
            templateUrl: '/assets/views/components/sc-meetings/sc-meetings.tpl.html',
            controller: ScMeetingsController,
            bindings: {
                header: '<',
            }
        });

    /**
     * @property {string} tenantId
     * @property {string} carParkId
     *
     * @param $scope
     * @param $interval
     * @param $moment
     * @param {InvitationsApiService} InvitationsApiService
     * @param {ScMeetingsService} ScMeetingsService
     * @constructor
     */
    function ScMeetingsController(
        $scope,
        $interval,
        $moment,
        InvitationsApiService,
        ScMeetingsService,
    ) {
        const $ctrl = this;

        /**
         * @type {SCInvitation[]}
         */
        $scope.invitations = [];
        $scope.loading = true;
        $scope.errorMessage = null;
        $scope.header = $ctrl.header;

        $ctrl.$onInit = () => {
            _initMeetings();
        };

        $scope.relativeTime = ScMeetingsService.relativeTime;
        $scope.meetingClass = ScMeetingsService.meetingClass;
        $scope.isToday = ScMeetingsService.isToday;
        $scope.isPast = ScMeetingsService.isPast;

        function _initMeetings() {
            const REFRESH_INTERVAL_MS = 60000;

            return _loadMeetings().then(
                () => {
                    $interval(() => _loadMeetings(), REFRESH_INTERVAL_MS);
                }
            );
        }

        function _filterDeletedInvitations(invitations) {
            const DELETED_STATUS = 3;

            return invitations.filter(invitation => invitation.status !== DELETED_STATUS);
        }

        function _loadMeetings() {
            return InvitationsApiService
                .getInvitations()
                .then(response => {
                    $scope.invitations = _filterDeletedInvitations(response);
                    $scope.errorMessage = !$scope.invitations.length
                        ? 'No meetings'
                        : null;
                })
                .catch(() => {
                    $scope.errorMessage = 'Internal server error';
                })
                .finally(() => {
                    $scope.loading = false;
                });
        }
    }
})();
