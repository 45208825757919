(function() {
    'use strict';

    angular.module('beacon.app').service('ContentTagsService',
        /**
         * @param {PopupService} PopupService
         */
        function(
            PopupService,
        ) {
            return {
                modifyTagsDialog,
            }

            /**
             * @param {string[]} existingTags
             * @return {Promise<string[]>}
             */
            function modifyTagsDialog(existingTags) {
                return PopupService.show({
                    backdrop: true,
                    template: `
                        <modify-tags-dialog
                            tags="tags" 
                            on-save="onSave(tags)"
                            on-cancel="onCancel()"
                        ></modify-tags-dialog>
                    `,
                    controller: (
                        $scope,
                        $uibModalInstance
                    ) => {
                        $scope.tags = existingTags;

                        $scope.onSave = (tags) => {
                            $uibModalInstance.close(tags);
                        };

                        $scope.onCancel = () => {
                            $uibModalInstance.dismiss();
                        };
                    }
                });
            }
        });
})();
