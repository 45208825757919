(function() {
    'use strict';

    angular.module('beacon.app')
        .component('contentTags', {
            templateUrl: '/assets/views/common/components/content-tags/content-tags.tpl.html',
            controller: ContentTagsController,
            bindings: {
                tags: '<',
                onTagClick: '&',
            }
        });

    function ContentTagsController(
        $scope,
    ) {
        const $ctrl = this;

        $ctrl.$onInit = () => {
            _initTags();
            _initChangesWatcher();
        }

        /**
         * @param {jQuery.Event} $event
         * @param {string} tag
         */
        $scope.onTagClick = ($event, tag) => {
            $event.preventDefault();
            $event.stopPropagation();

            $ctrl.onTagClick && $ctrl.onTagClick({ $event, tag });
        }

        function _initTags() {
            $scope.tags = $ctrl.tags;
        }

        function _initChangesWatcher() {
            $scope.$watch(
                () => $ctrl.tags,
                () => _initTags(),
            );
        }
    }
})();
